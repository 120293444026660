
<script setup>
import { useRuntimeConfig } from '#imports';
import { useAuthentication } from '@/composables/use-authentication';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useSnackbar from '@/components/commons/global-snack/composables';

const { show: showSnackbar } = useSnackbar();

const { currentUser, signout } = useAuthentication();
const route = useRoute();
const routeName = computed(() => route.name);

const picURL = computed(() => currentUser.value?.picURL);
const formattedName = computed(() => {
  return currentUser.value?.formattedName;
});
const email = computed(() => {
  return currentUser.value?.email;
});

async function onLogout () {
  await signout();
  showSnackbar('You have been successfully logged out.', { type: 'success' });
  setTimeout(() => {
    globalThis.location.href = '/';
  }, 500);
};

function onClickDoBlur () {
  if (routeName.value === 'settings-account') return;
  globalThis.document.getElementById('menuProfileItemRef').blur();
}

const appVersion = useRuntimeConfig().public?.appVersion;
</script>

<template>
<div class="dropdown dropdown-end">
  <div class="avatar mr-3 hover:cursor-pointer" tabindex="0">
    <div
      class="h-10 rounded-full bg-slate-300 hover:ring hover:ring-neutral-200"
      :class="{
        'ring ring-neutral-300': routeName === 'settings-account',
      }"
    >
      <img v-if="picURL" :src="picURL" />
      <div v-else class="flex items-center justify-center h-full">
        <i  class="las la-user text-2xl"></i>
      </div>
    </div>
  </div>
  <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box">
    <li
      class="block w-[300px]"
    >
      <nuxt-link id="menuProfileItemRef" class="flex flex-col items-start" to="/settings/account/personal" @click="onClickDoBlur">
        <span class="block w-full break-all font-medium">{{ formattedName }}</span>
        <span class="block w-full break-all">{{ email }}</span>
      </nuxt-link>
    </li>
    <li><a @click="onLogout">Log out</a></li>
    <span class="text-xs ml-3 mt-2">App Version: {{ appVersion }}</span>
  </ul>
</div>
</template>